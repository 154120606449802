<template>
    <div class="container">
        <h1>Hydronic Manometer COMADRONIC AC6</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>2 kPa to 600 kPa.</span></h4>
                    <h4>Measurement Accuracy: <span>+/- 1% or +/- 0.2 kPa, whichever is the greater.</span></h4>
                    <h4>Maximum Allowable Static Pressure: <span>20 bar.</span></h4>
                    <h4>Effective Operating Time: <span>20 hours with 1 x standard Alkaline PP3 battery.</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/water/w1.jpg" alt="">
                </div>
            </div>
            <h1>Hydronic Manometer TSI HM685</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Differential Pressure: ±300 psi (±2068 kPa) <br> Gauge Pressure: -20 to 300 psi (-138 to 2068 kPa) <br> Flow: ± 99,999 USGPM <br> Temperature Probe: -40 to 250°F (-40 to 121°C)</span></h4>
                    <h4>Measurement Accuracy: <span>Pressure: ±1% of reading +.072 psi (0.5 kPa) <br> Flow: per pressure accuracy + valve deviation <br> Temperature: ±0.5% of reading +1.2°F (0.7°C)</span></h4>
                    <h4>Units: <span>Pressure:psi, in. H2O, ft H2O, kPa, mm Hg, in. Hg,m H2O, bar <br> Flow:USGPM, UKGPM, m3/h, l/s, l/m <br> Temperature: degrees F, degrees C</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/water/w2.jpg" alt="">
                </div>
            </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}
.row{
    border-bottom: 2px solid #075daa;
}
.cont2{
    padding-bottom: 10px;
}
@media (max-width: 281px) {
    h1{
        font-size:24px;
    }
    h4{
        font-size: 20px;
    }
    span{
        font-size: 18px;
    }
}
</style>