 <template>
  <vision />
</template>

<script>
// @ is an alias to /src
import  Vision from "@/components/About/Vision.vue";

export default {
  name: "Home",
  components: {
    Vision,
  },
};
</script>
