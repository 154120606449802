<template>
  <section class="upHeader">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <a href="#" @click="$router.push('/')"><img src="../images/logo.jpg" alt="" /></a>
        </div>
        <div class="contact col-lg-3 col-md-3">
          <span>4M. El-Nabawi El-Mohandes <br />Nasr City, Cairo.</span>
        </div>
        <div class="contact col-lg-2 col-md-3">
          <p>(+20) 223515571</p>
          <p>(+20) 1001648112</p>
        </div>
        <div class="iconSocial">
          <social-icons />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SocialIcons from "@/components/SocialIcons.vue";
export default {
  name: "UpHeader",
  components: {
    SocialIcons,
  },
};
</script>
<style lang="scss" scoped>
section {
  padding: 20px 0;
}
p,
span {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 13px;
}
img {
  height: auto;
  max-width: 100%;
}
@media (max-width: 767px) {
  .contact {
    display: none;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .iconSocial {
    display: none;
  }
}
@media (max-width: 991px) {
  p {
    font-size: 12px;
  }
  span {
    font-size: 11px;
    font-weight: 500;
  }
  .iconSocial {
    z-index: 10;
    position: absolute;
    right: 30px;
    top: 20px;
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.upHeader{
   animation: 1s ease-out 0s 1 slideIn;
}
</style>
