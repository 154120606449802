 <template>
  <testing-balancing />
</template>

<script>
// @ is an alias to /src
import  TestingBalancing from "@/components/Services/TestingBalancing.vue";

export default {
  name: "Home",
  components: {
    TestingBalancing,
  },
};
</script>
