<template>
  <egy-projects />
</template>

<script>
import  EgyProjects from "@/components/Projects/EgyProjects.vue";

export default {
  name:"Community",
   components: {
    EgyProjects,
  },
}
</script>
