<template>
<section>
        <h1>About Balance Egypt</h1>
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12">
                 <h5>Since 2017</h5>
                 <div class="imgBox">
                     <img src="@/images/about/a1.jpg" alt="" class="">
                 </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 details">
                <p>Created in 2017, Balance Co., for Testing Adjusting & Balancing.  is a family owned and operated independent HVAC Testing, Adjusting & Balancing (TAB) company. We specialize in Air & Hydronic Testing & Balancing, Damper Testing), HVAC Duct Pressure Testing, , Sound Readings, HVAC System Analysis & Consulting. With over 20 employees (Balance Co) provides an adequate number of engneers and techniciang and supervisors to proficiently accomplish on-time all TAB related work. Mechanical Test & Balance, Balance Co. looks forward to servicing your TAB needs and creating lasting business relationships built on integrity and dependability. </p>
            </div>
        </div>
        
    </div>
</section>

</template>

<style lang="scss" scoped>
section{
    margin:20px 0;
}
h1{
    font-weight: bold;
    color: #075daa;
    font-family: "Roboto Condensed", sans-serif;
    text-align: center;
    padding: 30px 0;
    font-size: 36px;
}
h5{
    font-family:Arial, Helvetica, sans-serif;
    font-size: 22px;
}
p{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
img{
    padding: 0;
    width: 100%;
    height: auto;
}
@media (min-width:767px){
.details{
    margin-top:20px;
}
}
@media (max-width:375px){
    h1{
    font-size:26px;
}
}
</style>