<template>
   <section>
       <h1>Our Reports</h1>
       <div class="container">
           <div class="row">
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/ahu.jpg" class="imgSide" @click="$router.push('/Ahu')">
                   </div>                 
                    <p>AHU Formate</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/chiller.jpg" class="imgSide" @click="$router.push('/Chiller')">
                   </div>                 
                    <p>CHILLER Formate</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/cooling.jpg" class="imgSide" @click="$router.push('/Cooling')">
                   </div>                 
                    <p>COOLING TOWER FORMATE</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/fans.jpg" class="imgSide" @click="$router.push('/Fans')">
                   </div>                 
                    <p>Fans Formate</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/fcu1.jpg" class="imgSide" @click="$router.push('/Fcu')">
                   </div>                 
                    <p>FCU Formate</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/pump.jpg" class="imgSide" @click="$router.push('/Pumps')">
                   </div>                 
                    <p>PUMPS Formate</p>
               </div>
                <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/sound.jpg" class="imgSide" @click="$router.push('/Sound')">
                   </div>                 
                    <p>Sound Level</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/static.jpg" class="imgSide" @click="$router.push('/Static')">
                   </div>                 
                    <p>Static B.V Formate</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/reports/main/temp.jpg" class="imgSide" @click="$router.push('/Temp')">
                   </div>                 
                    <p>Temp. & RH%</p>
               </div>
           </div>
       </div>
    <div class="container">
        <div class="download">
            <svg xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true" focusable="false"
               width="1.5em" height="1.5em" 
               style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" 
               viewBox="0 0 16 16"><g fill="#ffff">
                   <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318C0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/></g></svg>
            <a href="https://www.mediafire.com/file/24eos2acq39vnja/Balance+Reports.rar/file" target="_blank">Download Reports</a>
        </div>
    </div>
   </section>
</template>

<style lang="scss" scoped>
.container{
    padding-top: 10px ;
    padding-bottom: 38px;
}
h1{
    font-weight: bold;
    color: #075daa;
    font-family: "Roboto Condensed", sans-serif;
    text-align: center;
    padding: 30px 0;
    font-size: 36px;
}
p{
    text-align: center;
    font-size: 18px;
    color:red;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
}
.imgSide{
    padding: 0;
    cursor: -webkit-grab; cursor: grab;
}
//* [1] The container */
.img-hover-zoom {
   /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  margin:10px 0;
  
  border: 4px solid #075daa;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
  width:100%;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
  filter: grayscale(50%);
}
.download {
  font-family: poppins;
  max-width: 280px;
  margin: 0 auto;
  display: block;
  background-color:#075daa;
  color: white;
  padding: 15px 0px;
  width: 100%;
  font-size: 20px;
  text-align: center;
  border-radius: 32px;
  margin-top: 25px;
  text-decoration: none;
}

a{
    color:white;
    padding: 0 8px;
}
a:link {
  text-decoration: none;
}
a:hover{
color:white;
}
</style>