<template>
  <community-home />
</template>

<script>
import  CommunityHome from "@/components/home/CommunityHome.vue";

export default {
  name:"Community",
   components: {
    CommunityHome,
  },
}
</script>
