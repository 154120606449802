 <template>
  <clean-room />
</template>

<script>
// @ is an alias to /src
import  CleanRoom from "@/components/Services/CleanRoom.vue";

export default {
  name: "Home",
  components: {
    CleanRoom,
  },
};
</script>
