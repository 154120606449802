 <template>
  <background />
</template>

<script>
// @ is an alias to /src
import  Background from "@/components/About/BackGround.vue";

export default {
  name: "Home",
  components: {
    Background,
  },
};
</script>
