<template>
  <div class="container">
      <div class="content">
        <h1>Our Background</h1>
          <p>Balance strongly believes in continuing education for our employees in order to deliver the highest quality of service., we are held to a higher standard, by which we MUST abide, working training and knowledge, our technicians are fully qualified to perform all testing and balancing procedures, and can handle any project, regardless of size or complexity.</p>
          <p class="balance-word">At Balance we are interested in analyzing technical problems, finding out what they are, and giving customers the right technical solutions, which contributes to the speedy completion of projects and their solutions according to the correct requirements.</p>
          <p>Balance brings our significant resources and years of experience together with specialized training to ensure that the TAB projects we perform are completed on time, with accuracy and thoroughness. Our process is to test and balance HVAC systems to meet the design intent. A thorough review during procedure development will ensure the results are predicted and that inconsistencies are fully examined and remedied.</p>
      </div>
  </div>
  <div class="container">
        <div class="download">
            <svg xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true" focusable="false"
               width="1.5em" height="1.5em" 
               style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" 
               viewBox="0 0 16 16"><g fill="#ffff">
                   <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318C0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/></g></svg>
            <a href="https://www.mediafire.com/file/xwskza7xxa2lt90/BALANCE+SUBMITTAL-1.pdf/file" target="_blank">Balance Profile</a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
div {
  padding: 40px 30px;
  
}
h1 {
  font-weight: bold;
  color: #075daa;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  padding: 30px 0;
  font-size: 36px;
}
.balance-word{
  font-weight: bold;
  text-decoration: underline;
}

.download {
  font-family: poppins;
  max-width: 280px;
  margin: 0 auto;
  display: block;
  background-color:#075daa;
  color: white;
  padding: 15px 0px;
  width: 100%;
  font-size: 20px;
  text-align: center;
  border-radius: 32px;
  margin-top: 25px;
  text-decoration: none;
}

a{
    color:white;
    padding: 0 8px;
}
a:link {
  text-decoration: none;
}
a:hover{
color:white;
}
</style>
