<template>
  <section>
    <h1>Our Community</h1>
    <div class="container">
      <div class="box">
        <div class="card">
          <div class="imgBx">
            <img src="@/images/community/c1.jpg" alt="images" />
          </div>
          <div class="details">
            <h2>Eng.Mohamed Refaat<br /><span>General manager</span></h2>
            <span>Mohamed.refaat@balance-eg.com</span>
          </div>
        </div>

        <div class="card">
          <div class="imgBx">
            <img src="@/images/community/c3.jpg" alt="images" />
          </div>
          <div class="details">
            <h2>Eng.Mahmoud Ali<br /><span>Deputy Manager</span></h2>
            <span>Mahmoud.ali@balance-eg.com</span>
          </div>
        </div>

        <div class="card">
          <div class="imgBx">
            <img src="@/images/community/c2.jpg" alt="images" />
          </div>
          <div class="details">
            <h2>Eng.Mohamed Elsaeed<br /><span>TAB head department</span></h2>
            <span>mohamed.elsaeed@balance-eg.com</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
h1 {
  font-weight: bold;
  color: #075daa;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  padding: 30px 0;
  font-size: 36px;
}
.container {
  padding: 15px 0;
}
.box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
}
.card {
  position: relative;
  width: 300px;
  height: 350px;
  background: #fff;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
.card:before,
.card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  transition: 0.5s;
  z-index: -1;
}
.card:hover:before {
  transform: rotate(20deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
.card:hover:after {
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
.card .imgBx {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
}

.card:hover .imgBx {
  bottom: 100px;
}

.card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card .details {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 80px;
  text-align: center;
}

.card .details h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  color: rgb(59, 59, 59);
  text-transform: uppercase;
}

.card .details h2 span {
  font-weight: 500;
  font-size: 16px;
  color: #257bd1;
  display: block;
  margin-top: 5px;
}
@media (max-width: 375px) {
  h1 {
    font-size: 26px;
  }
}
@media (max-width: 321px) {
  .box {
    margin-left: -25px;
  }
}
</style>
