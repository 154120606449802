 <template>
  <air-measurements />
</template>

<script>
// @ is an alias to /src
import  AirMeasurements from "@/components/Instrumentation/AirMeasurements.vue";

export default {
  name: "Home",
  components: {
    AirMeasurements,
  },
};
</script>
