<template>
  <up-header />
  <app-header />
  <top-click />
  <router-view />
  <app-footer />
</template>


<script>
import  UpHeader from "@/components/UpHeader.vue";
import  AppHeader from "@/components/AppHeader.vue";
import  TopClick from "@/components/TopClick.vue";
import  AppFooter from "@/components/AppFooter.vue";
export default {
  name: "App",
  components: {
    UpHeader,
    AppHeader,
    TopClick,
    AppFooter,
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
