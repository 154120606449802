<template>
  <div  class="buttons-social">
  <ul class="row list-unstyled">
      <li class="fb">
        <a href="https://www.facebook.com/Balance-for-Hvac-systems-balancing-1902529503332820/" target="_blank">
          <svg
           xmlns="http://www.w3.org/2000/svg"     
           width="2.5em" height="2.5em"  
           viewBox="0 0 24 24">
           <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z" 
           />
           </svg>
        </a>
      </li>
      <li class="twitter">
        <a href="#">
          <svg xmlns="http://www.w3.org/2000/svg" 
          width="2.5em" height="2.5em" 
          viewBox="0 0 32 32">
          <path d="M11.92 24.94A12.76 12.76 0 0 0 24.76 12.1v-.59A9.4 9.4 0 0 0 27 9.18a9.31 9.31 0 0 1-2.59.71a4.56 4.56 0 0 0 2-2.5a8.89 8.89 0 0 1-2.86 1.1a4.52 4.52 0 0 0-7.7 4.11a12.79 12.79 0 0 1-9.3-4.71a4.51 4.51 0 0 0 1.4 6a4.47 4.47 0 0 1-2-.56v.05a4.53 4.53 0 0 0 3.55 4.45a4.53 4.53 0 0 1-2 .08A4.51 4.51 0 0 0 11.68 21a9.05 9.05 0 0 1-5.61 2A9.77 9.77 0 0 1 5 22.91a12.77 12.77 0 0 0 6.92 2" 
          />
          </svg>
          
        </a>
      </li>
 
      <li class="linkedIn">
          <a href="#">
              <svg xmlns="http://www.w3.org/2000/svg" 
                width="2.5em" height="2.5em" 
                viewBox="0 0 32 32">
                <path d="M8.268 28H2.463V9.306h5.805zM5.362 6.756C3.506 6.756 2 5.218 2 3.362a3.362 3.362 0 0 1 6.724 0c0 1.856-1.506 3.394-3.362 3.394zM29.994 28h-5.792v-9.1c0-2.169-.044-4.95-3.018-4.95c-3.018 0-3.481 2.356-3.481 4.794V28h-5.799V9.306h5.567v2.55h.081c.775-1.469 2.668-3.019 5.492-3.019c5.875 0 6.955 3.869 6.955 8.894V28z" /></svg>
          </a>
      </li>
  </ul>
  </div>    
       
</template>

<script>
 
  export default {
    name: 'SocialIcons',
    components: {
      
    }
  }
</script>
<style scoped>
svg{
    
    background-color: white;
    border-radius: 70%;
    border :1px solid white;
    padding: 5px;
}
svg:hover{
    background-color: #12baf0;
    border :1px solid #12baf0;  
}
svg:hover>path{
    fill: white;
}
path{
    fill:black;
}
li{
  padding: 5px;
  margin-left: 12px;
}

</style>