<template>
    <section>
        <div>
            <h1>Balance</h1>
            <p>We are the Future</p>
        </div>
        
    </section>
    
</template>
<style lang="scss" scoped>
section{
  background-image: url("../images/bbg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  min-height:100%;
}
div{
    text-align: center;
    color: #12baf0;
    padding: 300px 0;
}
h1{
    font-size: 42px;
    line-height: .1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}
p{
    font-size: 36px;
    line-height: .5;
    font-weight: bold;
}
@media (max-width:300px){
    h1{
        font-size: 22px;
    }
    p{
        font-size: 18px;
    }
}
</style>