<template>
    <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 cont1">
                    <h1>Testing</h1>
                    <p>The Engineering Company for Testing and Balancing Services "TAB" offers a full support to project team to achieve their client goals and contractual obligations, through a systematic engineering procedures and method statements tailored made to the project. Through the most experienced and well trained teams in TAB field in market and by using of the most advanced TAB instruments and tools</p>                 
                </div>
                <div class="col-lg-4 col-md-4 cont2">
                    <img src="@/images/services/s3.jpg" alt="">
                </div>
            </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
}
@media (min-width:765px)and(max-width:768px)
{
    img{
    margin-top:45px;
}
}

</style>