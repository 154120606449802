<template>
    <div class="container"> 
            <h1>PHOTO/CONTACT TACHOMETER DT-2230</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Photo Tachometer : 5 to 99,999 RPM. <br> Contact Tachometer : 0.5 to 19,999 RPM.</span></h4>
                    <h4>Measurement Accuracy: <span>± ( 0.1% + 1 digit</span></h4>
                    <h4>Units: <span>RPM</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/electrical/e3.jpg" alt="">
                </div>
            </div>
            
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}
.row{
    border-bottom: 2px solid #075daa;
}
.cont2{
    padding-bottom: 10px;
}
@media (max-width: 281px) {
    h1{
        font-size:22px;
    }
    h4{
        font-size: 18px;
    }
    span{
        font-size: 16px;
    }
}
</style>