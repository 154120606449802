<template>
  <report />
</template>

<script>
import  Report from "@/components/Report.vue";

export default {
  name:"Community",
   components: {
    Report,
  },
}
</script>
