<template>
    <div class="container">
        <div class="row">
            <img src="@/images/partner/almaza.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/bankmisr.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/deo.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/dokhan.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/ds.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/erc.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/mahgoub.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/marcyrl.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/moe.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/mpw.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/optaline.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/uturn.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/vox.png" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/alef.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/alfattah.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/amoun.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/anba.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/bosporus.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/darna.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/downtown.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/egyptpost.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/evapharma.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/hyper.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/katamyahospital.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/mansoura.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/mcdonalds.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/moh.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/nccc.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/nekhl.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/oreal.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/portomarina.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/reefoasis.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/wakipharma.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/zagazighospital.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            <img src="@/images/partner/zara.jpg" alt="" class="col-lg-2 col-md-3 col-sm-4">
            
        </div>
        
    </div> 
</template>
<style lang="scss" scoped>
.container{
    padding: 50px 10px;
}
img {
    margin-bottom: 5px;
}
</style>