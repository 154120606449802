<template>
  <head-section />
  <about-home />
  <project-home />
  <services-home />
  <partner-home />
  <community-home />
</template>

<script>
// @ is an alias to /src
import  HeadSection from "@/components/HeadSection.vue";
import  AboutHome from "@/components/home/AboutHome.vue";
import  ProjectHome from "@/components/home/ProjectHome.vue";
import  ServicesHome from "@/components/home/ServicesHome.vue";
import  PartnerHome from "@/components/home/PartnerHome.vue";
import  CommunityHome from "@/components/home/CommunityHome.vue";

export default {
  name: "Home",
  components: {
     AboutHome,
    ProjectHome,
    ServicesHome,
    PartnerHome,
    CommunityHome,
    HeadSection
  },
};
</script>
