<template>
    <div class="container">
        <h1>Capture Hood TESTO 240</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Flow velocity: 0 to 14 m/s /0 to 2750 ft/min <br> Volume flow: 50 to 4000 m³/h /30 to 2350 cfm / 11 to 1100 l/s</span></h4>
                    <h4>Measurement Accuracy: <span>Flow velocity: no accuracy specification since calculated variable <br> Volume flow : ±3% of m.v. +7 cfm at +72 °F, 405 in H₂O (50 to 2,060 cfm)</span></h4>
                    <h4>Units: <span>Flow velocity : m/s / ft/min <br> Volume flow: m³/h / cfm / l/s</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/air/a1.jpg" alt="">
                </div>
            </div> 
            <h1>TSI-Capture Hood Ph 731</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Velocity: 25 to 15,500 ft/min (0.125 to78m/s) <br> pitot probes; 25 to 5,000 ft/min (0.125 to25 m/s) <br> air flow probe; 25 to 2,500 ft/min (0.125 to12.5 m/s) <br> velocity matrix Volume: 25 to 2,500 ft3/min (42 to 4250 m3/h) capture hood supply and return</span></h4>
                    <h4>Measurement Accuracy: <span>Velocity: ±3% of reading ±7 ft/min(0.04 m/s) >50 ft/min (>0.25m/s) <br> Volume: ±3% of reading ±7 ft3/min(12 m3/h) >50 ft3/min (>85m3/h)</span></h4>
                    <h4>Units: <span>Flow velocity : m/s / ft/min <br> Volume flow: m³/h m³/min / cfm / l/s</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/air/a2.jpg" alt="">
                </div>
            </div>
            <h1>Vane Anemometer TESTO 417</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Velocity: 0.3 : 20 m/sec <br> Temperature: 0 : +50 C˚ / 32 : 122 F˚ </span></h4>
                    <h4>Measurement Accuracy: <span>Velocity: ±0.1 m/sec + 1.5 % of the reading Temperature: ± 0.5 C˚ / ± 0.9 F˚ </span></h4>
                    <h4>Units: <span>Velocity: m/sec , F/min Temperature: C˚ , F˚ </span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/air/a3.jpg" alt="">
                </div>
            </div>
            <h1>Micromanometer PVM620</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Static / Differential Pressure:-15 to +15 in. H2O (-28.0 to +28.0 mm Hg, -3735 to+3735 Pa) <br> Velocity From a Pitot Tube: 250 to 15500 ft/min (1.27 to 78.7 m/s) </span></h4>
                    <h4>Measurement Accuracy: <span>Static / Differential Pressure: ±1% of reading ±0.005 in. H2O (±0.01 mm Hg, ±1 Pa) <br> Velocity From a Pitot Tube: 250 to 15500 ft/min (1.27 to 78.7 m/s) </span></h4>
                    <h4>Units: <span>Static / Differential Pressure: in. H2O / Pa / mm Hg <br> Velocity From a Pitot Tube: ft/min / m/sec </span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/air/a4.jpg" alt="">
                </div>
            </div>
            <h1>AIR Flowmeter AVM430</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Velocity : 0 to 6000 ft/min (0 to 30 m/s) <br> Temperature : 0 to 200°F (-18 to 93°C) </span></h4>
                    <h4>Measurement Accuracy: <span>Velocity : ±3% of reading or ±3 ft/min (±0.015 m/s), <br> which ever is greater  <br> Temperature : ±0.5°F (±0.3°C) </span></h4>
                    <h4>Units: <span>Velocity : ft/min , m/s Temperature : °F ,°C </span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/air/a5.jpg" alt="">
                </div>
            </div>
            <h1>Multi function Micromanometer TSI-TA460</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Velocity at probe: 0 to 9999 ft/min (0 to 50 m/s) <br> Velocity From a Pitot Tube: 250 to 15500 ft/min (1.27 to 78.7 m/s) </span></h4>
                    <h4>Measurement Accuracy: <span>Velocity at probe: ±3% of reading or ±3 ft/min (±0.015 m/s) , <br> whichever is Greater <br> Velocity From a Pitot Tube: ±1.5% at 2000 ft/min (10.16 m/s) </span></h4>
                    <h4>Units: <span>Static / Differential Pressure: in. H2O / Pa / mm Hg <br> Velocity From a Pitot Tube: ft/min / m/sec </span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/air/a6.jpg" alt="">
                </div>
            </div>
            <h1>Digital Thermohygrometer with Wireless Technology testo 625</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>0 to +100 %RH <br> -14 to +140 °F </span></h4>
                    <h4>Measurement Accuracy: <span>±2.5 %RH (+5 to +95 %RH) <br> ±0.9 °F </span></h4>
                    <h4>Units: <span>°F, percent RH</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/electrical/e1.jpg" alt="">
                </div>
            </div>
            <h1>Mini thermometer Part no. 0900 0530</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>-50 to +150 °C </span></h4>
                    <h4>Measurement Accuracy: <span>±1 °C (-10 to +100 °C) <br> ±2 °C (remaining range)</span></h4>
                    <h4>Units: <span>°F, °C</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/electrical/e2.jpg" alt="">
                </div>
            </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}
.row{
    border-bottom: 2px solid #075daa;
}
.cont2{
    padding-bottom: 10px;
}
@media (max-width: 281px) {
    h1{
        font-size:24px;
    }
    h4{
        font-size: 20px;
    }
    span{
        font-size: 18px;
    }
}
</style>