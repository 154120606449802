<template>
    <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 cont1">
                    <h1>Validation</h1>
                    <p>Validation is defined as the establishing of documented evidence which provides a high degree of assurance that a planned process will consistently perform according to the intended specified outcomes. Validation studies are performed for analytical tests, equipment, facility systems such as air, water, steam, and for processes such as the manufacturing processes, cleaning, sterilization, sterile filling (WHO definition).</p>                 
                </div>
                <div class="col-lg-4 col-md-4 cont2">
                    <img src="@/images/services/v1.jpg" alt="">
                </div>
            </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
}
@media (min-width:765px)and(max-width:768px)
{
    img{
    margin-top:45px;
}
}

</style>