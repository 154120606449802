<template>
  <div class="container">
      <div class="content">
        <h1>Our Mission</h1>
        <p>Balance aspire to serve public and private sectors to the absolute pinnacle of its ability and enrich the industry. This cannot be done without state of the art technology used by extensively trained individuals and a proactive approach to enhance its operations quality and standards. <br> Balance professional team apply a systematic approach to ensure the highest standards of design and construction, whilst implementing value engineering and excellent management to provide the best customer satisfaction through a professional innovative approach. <br> Balance target is to complete projects using the best practices and the highest possible quality within committed time and site conditions through optimum management of project resources to the ultimate level of customer satisfaction.</p>
      </div>
  </div>
</template>

<style lang="scss" scoped>
div {
  padding: 40px 30px;
  
}
h1 {
  font-weight: bold;
  color: #075daa;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  padding: 30px 0;
  font-size: 36px;
}
</style>
