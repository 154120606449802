<template>
  <div class="container">
      <div class="content">
          <h1>Our Vision</h1>
          <p>Balance accumulated experience make us leaders in providing integrated engineering service and solutions to diverse spectrum of clients. With the experience and support of a dedicated professional workforce, our performance-based services, combined with our expert knowledge, processes and systems, help customers achieve optimum asset performance. In addition, clients want to work with people who understand their operations, who listen to their needs and who meet their business objectives. Balance treats clients as partners on each project. Balance project teams include leading experts who apply the best practices for reliable and as designed system performance.</p>
      </div>
  </div>
</template>

<style lang="scss" scoped>
div {
  padding: 40px 30px;
  
}
h1 {
  font-weight: bold;
  color: #075daa;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  padding: 30px 0;
  font-size: 36px;
}
</style>
