 <template>
  <clients />
</template>

<script>
// @ is an alias to /src
import  Clients from "@/components/About/Clients.vue";

export default {
  name: "Home",
  components: {
    Clients,
  },
};
</script>
