 <template>
  <sound-measure />
</template>

<script>
// @ is an alias to /src
import  SoundMeasure from "@/components/Services/SoundMeasure.vue";

export default {
  name: "Home",
  components: {
    SoundMeasure,
  },
};
</script>
