 <template>
  <electrical-measurements />
</template>

<script>
// @ is an alias to /src
import  ElectricalMeasurements from "@/components/Instrumentation/ElectricalMeasurements.vue";

export default {
  name: "Home",
  components: {
    ElectricalMeasurements,
  },
};
</script>
