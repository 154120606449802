 <template>
  <clean-room-i />
</template>

<script>
// @ is an alias to /src
import  CleanRoomI from "@/components/Instrumentation/CleanRoom.vue";

export default {
  name: "Home",
  components: {
    CleanRoomI,
  },
};
</script>
