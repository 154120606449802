import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Services from "../views/Services.vue";
import Projects from "../views/Projects.vue";
import Instrumentation from "../views/Instrumentation.vue";
import Community from "../views/Community.vue";
import Reports from "../views/Reports.vue";

import Background from "../views/About/Background.vue";
import Mission from "../views/About/Mission.vue";
import Vision from "../views/About/Vision.vue";
import Clients from "../views/About/Clients.vue";

import TestingBalancing from "../views/Services/TestingBalancing.vue";
import CleanRoom from "../views/Services/CleanRoom.vue";
import SoundMeasure from "../views/Services/SoundMeasure.vue";

import AirMeasurements from "../views/Instrumentation/AirMeasurements.vue";
import WaterMeasurements from "../views/Instrumentation/WaterMeasurements.vue";
import SoundMeasurements from "../views/Instrumentation/SoundMeasurements.vue";
import CleanRoomI from "../views/Instrumentation/CleanRoom.vue";
import ElectricalMeasurements from "../views/Instrumentation/ElectricalMeasurements.vue";

import EgyProjects from "../views/Projects/EgyProjects.vue";
import NatProjects from "../views/Projects/NatProjects.vue";

import EvaPharma from "../views/Egypt/Eva Pharma.vue";
import OREAL from "../views/Egypt/Le oreal.vue";
import WakiPharma from "../views/Egypt/Waki Pharma.vue";
import MarcyrlPharma from "../views/Egypt/Marcyrl Pharma.vue";
import ZagazigUniversityHospital from "../views/Egypt/Zagazig University Hospital.vue";
import NekhlcentralHospital from "../views/Egypt/Nekhl central Hospital.vue";
import ReefOasis from "../views/Egypt/Reef Oasis.vue";
import AmounPharmaceutical from "../views/Egypt/AMOUN Pharmaceutical.vue";
import VoxCinema from "../views/Egypt/Vox Cinema.vue";
import MallEgyptShops from "../views/Egypt/Mall of Egypt shops.vue";
import MahgoubCeramicGallery from "../views/Egypt/Mahgoub ceramic gallery.vue";
import BanqueEgyptClub from "../views/Egypt/Banque of Egypt club.vue";
import SpecializedMedicalCouncils from "../views/Egypt/Specialized medical councils.vue";
import KatameyaClinicHospital from "../views/Egypt/Katameya Clinic Hospital.vue";
import MasjedAlfattahAlaleemHall from "../views/Egypt/Masjed alfattah alaleem Hall.vue";
import EvaNewPharmaFactory from "../views/Egypt/Eva new pharma factory of HORUS.vue";
import EasternTobaccoCompany from "../views/Egypt/Eastern tobacco company.vue";
import PortoMarinaAlexandria from "../views/Egypt/Porto Marina Alexandria.vue";
import DownTownMall from "../views/Egypt/Down Town Mall.vue";
import AnbaAntoniuschurch from "../views/Egypt/Anba Antonius church.vue";
import DeoCairo from "../views/Egypt/DEO Cairo.vue";
import HyperOne from "../views/Egypt/Hyper one.vue";
import EgyptianPostOffice from "../views/Egypt/Egyptian post office.vue";
import DarnaMall from "../views/Egypt/Darna Mall.vue";
import OptalineFactory from "../views/Egypt/OPTALINE Factory.vue";
import Mcdonalds from "../views/Egypt/McDonalds.vue";
import AlgalalahResort from "../views/Egypt/Al GALALAH Resort.vue";
import MansouraUniversityHospital from "../views/Egypt/Mansoura University Hospital.vue";
import EvaAdminBuilding from "../views/Egypt/EVA Admin Building.vue";
import ElectronicsResearchCenter from "../views/Egypt/Electronics Research Center.vue";
import AlmazaCityCenter from "../views/Egypt/Almaza city center(shopes).vue";
import Albayadia from "../views/Egypt/ALBAYADIA(ISIS) Hospital.vue";
import Nccc from "../views/Egypt/NCCC.vue";

import MPW from "../views/International/Mpw.vue";

import Ahu from "../views/reports/ahu.vue";
import Chiller from "../views/reports/chiller.vue";
import Cooling from "../views/reports/cooling.vue";
import Fans from "../views/reports/fans.vue";
import Fcu from "../views/reports/fcu.vue";
import Pumps from "../views/reports/pumps.vue";
import Sound from "../views/reports/sound.vue";
import Static from "../views/reports/static.vue";
import Temp from "../views/reports/temp.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/About",
    name: "About",
    component: About,
  },
  {
    path: "/Services",
    name: "Services",
    component: Services ,
  },
  {
    path: "/Projects",
    name: "Projects",
    component: Projects ,
  },
  {
    path: "/Instrumentation",
    name: "Instrumentation",
    component: Instrumentation,
  },
  {
    path: "/Community",
    name: "Community",
    component: Community,
  },
  {
    path: "/Reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/Background",
    name: "Background",
    component: Background,
  },
  {
    path: "/Mission",
    name: "Mission",
    component: Mission,
  },
  {
    path: "/Vision",
    name: "Vision",
    component: Vision,
  },
  {
    path: "/Clients",
    name: "Clients",
    component: Clients,
  },
  {
    path: "/TestingBalancing",
    name: "TestingBalancing",
    component: TestingBalancing,
  },
  {
    path: "/CleanRoom",
    name: "CleanRoom",
    component: CleanRoom,
  },
  {
    path: "/SoundMeasure",
    name: "SoundMeasure",
    component: SoundMeasure,
  },
  {
    path: "/AirMeasurements",
    name: "AirMeasurements",
    component: AirMeasurements,
  },
  {
    path: "/WaterMeasurements",
    name: "WaterMeasurements",
    component: WaterMeasurements,
  },
  {
    path: "/SoundMeasurements",
    name: "SoundMeasurements",
    component: SoundMeasurements,
  },
  {
    path: "/CleanRoomI",
    name: "CleanRoomI",
    component: CleanRoomI,
  },
  {
    path: "/ElectricalMeasurements",
    name: "ElectricalMeasurements",
    component: ElectricalMeasurements,
  },
  {
    path: "/EgyProjects",
    name: "EgyProjects",
    component: EgyProjects,
  },
  {
    path: "/NatProjects",
    name: "NatProjects",
    component: NatProjects,
  },
  {
    path: "/EvaPharma",
    name: "EvaPharma",
    component: EvaPharma,
  },
  {
    path: "/OREAL",
    name: "OREAL",
    component: OREAL,
  },
  {
    path: "/WakiPharma",
    name: "WakiPharma",
    component: WakiPharma,
  },
  {
    path: "/MarcyrlPharma",
    name: "MarcyrlPharma",
    component: MarcyrlPharma,
  },
  {
    path: "/ZagazigUniversityHospital",
    name: "ZagazigUniversityHospital",
    component: ZagazigUniversityHospital,
  },
  {
    path: "/NekhlcentralHospital",
    name: "NekhlcentralHospital",
    component: NekhlcentralHospital,
  },
  {
    path: "/ReefOasis",
    name: "ReefOasis",
    component: ReefOasis,
  },
  {
    path: "/AmounPharmaceutical",
    name: "AmounPharmaceutical",
    component: AmounPharmaceutical,
  },
  {
    path: "/VoxCinema",
    name: "VoxCinema",
    component: VoxCinema,
  },
  {
    path: "/MallEgyptShops",
    name: "MallEgyptShops",
    component: MallEgyptShops,
  },
  {
    path: "/MahgoubCeramicGallery", 
    name: "MahgoubCeramicGallery",
    component: MahgoubCeramicGallery,
  },
  {
    path: "/BanqueEgyptClub",
    name: "BanqueEgyptClub",
    component: BanqueEgyptClub,
  },
  {
    path: "/SpecializedMedicalCouncils",
    name: "SpecializedMedicalCouncils",
    component: SpecializedMedicalCouncils,
  },
  {
    path: "/KatameyaClinicHospital",
    name: "KatameyaClinicHospital",
    component: KatameyaClinicHospital,
  },
  {
    path: "/MasjedAlfattahAlaleemHall",
    name: "MasjedAlfattahAlaleemHall",
    component: MasjedAlfattahAlaleemHall,
  },
  {
    path: "/EvaNewPharmaFactory",
    name: "EvaNewPharmaFactory",
    component: EvaNewPharmaFactory,
  },
  {
    path: "/EasternTobaccoCompany",
    name: "EasternTobaccoCompany",
    component: EasternTobaccoCompany,
  },
  {
    path: "/PortoMarinaAlexandria",
    name: "PortoMarinaAlexandria",
    component: PortoMarinaAlexandria,
  },
  {
    path: "/DownTownMall",
    name: "DownTownMall",
    component: DownTownMall,
  },
  {
    path: "/AnbaAntoniuschurch",
    name: "AnbaAntoniuschurch",
    component: AnbaAntoniuschurch,
  },
  {
    path: "/DeoCairo",
    name: "DeoCairo",
    component: DeoCairo,
  },
  {
    path: "/HyperOne",
    name: "HyperOne",
    component: HyperOne,
  },
  {
    path: "/EgyptianPostOffice",
    name: "EgyptianPostOffice",
    component: EgyptianPostOffice,
  },
  {
    path: "/DarnaMall",
    name: "DarnaMall",
    component: DarnaMall,
  },
  {
    path: "/OptalineFactory",
    name: "OptalineFactory",
    component: OptalineFactory,
  },
  {
    path: "/Mcdonalds",
    name: "Mcdonalds",
    component: Mcdonalds,
  },
  {
    path: "/AlgalalahResort",
    name: "AlgalalahResort",
    component: AlgalalahResort,
  },
  {
    path: "/MansouraUniversityHospital",
    name: "MansouraUniversityHospital",
    component: MansouraUniversityHospital,
  },
  {
    path: "/EvaAdminBuilding",
    name: "EvaAdminBuilding",
    component: EvaAdminBuilding,
  },
  {
    path: "/ElectronicsResearchCenter",
    name: "ElectronicsResearchCenter",
    component: ElectronicsResearchCenter,
  },
  {
    path: "/AlmazaCityCenter",
    name: "AlmazaCityCenter",
    component: AlmazaCityCenter,
  },
  {
    path: "/Albayadia",
    name: "Albayadia",
    component: Albayadia,
  },
  {
    path: "/Nccc",
    name: "Nccc",
    component: Nccc,
  },
  {
    path: "/MPW",
    name: "MPW",
    component: MPW,
  },
  {
    path: "/Ahu",
    name: "Ahu",
    component: Ahu,
  },
  {
    path: "/Chiller",
    name: "Chiller",
    component: Chiller,
  },
  {
    path: "/Cooling",
    name: "Cooling",
    component: Cooling,
  },
  {
    path: "/Fans",
    name: "Fans",
    component: Fans,
  },
  {
    path: "/Fcu",
    name: "Fcu",
    component: Fcu,
  },
  {
    path: "/Pumps",
    name: "Pumps",
    component: Pumps,
  },
  {
    path: "/Sound",
    name: "Sound",
    component: Sound,
  },
  {
    path: "/Static",
    name: "Static",
    component: Static,
  },
  {
    path: "/Temp",
    name: "Temp",
    component: Temp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
