<template>
   <section>
       <h1>Our Services</h1>
       <div class="container">
           <div class="row">
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/services/s1.jpg" class="imgSide">
                   </div>                 
                    <p>COMADRONIC AC6</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/services/s2.jpg" class="imgSide">
                   </div>                 
                    <p>TSI HM685</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/services/s3.jpg" class="imgSide">
                   </div>                 
                    <p>TESTO 240</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/services/s4.jpg" class="imgSide">
                   </div>                 
                    <p>PH 731</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/services/s5.jpg" class="imgSide">
                   </div>                 
                    <p>TESTO 417</p>
               </div>
               <div class="col-lg-4 col-md-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/services/s6.jpg" class="imgSide">
                   </div>                 
                    <p>PVM620</p>
               </div>
           </div>
       </div>
   </section>
</template>

<style lang="scss" scoped>
.container{
    padding-top: 10px ;
    padding-bottom: 38px;
}
h1{
    font-weight: bold;
    color: #075daa;
    font-family: "Roboto Condensed", sans-serif;
    text-align: center;
    padding: 30px 0;
    font-size: 36px;
}
p{
    text-align: center;
    font-size: 18px;
    color:#939494;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
}
.imgSide{
    padding: 0;
    cursor: -webkit-grab; cursor: grab;
}
//* [1] The container */
.img-hover-zoom {
   /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  margin:10px 0;
  
  border: 2px solid #075daa;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
  width:100%;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
  filter: grayscale(50%);
}
</style>