<template>
    <div class="container">
        <h1>L'OREAL Cosmetics -Egypt</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Location: <span>10th of Ramadan</span></h4>
                    <h4>Scope of work: <span>Testing and balancing</span></h4>
                    <h4>Project size: <span>11 AHU-56 FCU-33 FAN-2 CHILLER-9 CHWP-3 VAV BOXES-253 AIR TERMINALS-68 B.V</span></h4>
                    <h4>Consultant&Contractor <span>L'OREAL Engineering Management (owner)</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/clients/oreal.jpg" alt="">
                </div>
            </div>
            
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}

</style>