 <template>
  <water-measurements />
</template>

<script>
// @ is an alias to /src
import  WaterMeasurements from "@/components/Instrumentation/WaterMeasurements.vue";

export default {
  name: "Home",
  components: {
    WaterMeasurements,
  },
};
</script>
