<template>
    
    <div class="container">
        <h1>PUMPS Formate</h1>
        <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-10">
            <img src="@/images/reports/pumps/pump1.jpg" alt="">
            <p>Pump</p>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-10">
            <img src="@/images/reports/pumps/pump2.jpg" alt="">
            <p>Pump</p>
        </div>
       
        </div>

    </div>
</template>
<style lang="scss" scoped>
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
p{
    text-align: center;
    color: red;
}
div img {
    width:100%;
}
img{
     border: 5px solid #555;
}
</style>
