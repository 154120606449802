<template>
  <nat-projects />
</template>

<script>
import  NatProjects from "@/components/Projects/NatProjects.vue";

export default {
  name:"Community",
   components: {
    NatProjects,
  },
}
</script>
