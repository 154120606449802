<template>
    <div class="container">
        <h1>Al GALALAH Resort</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Location: <span>AL Galalah City</span></h4>>
                    <h4>Project size: <span>111 Fcu’s- 81Fans – 225AIR TERMINALS</span></h4>
                    <h4>Owner: <span>Armed Forces Engineering Authority</span></h4>
                    <h4>Consultant: <span>SITES International</span></h4>
                    <h4>Contractor: <span>Advanced housing and contracting company</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/clients/galalh.jpg" alt="">
                </div>
            </div>
            
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}

</style>