<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="imgLogo col-lg-3 col-md-12 col-sm-12">
                    <img src="../images/footerlogo.png" alt="" />
                </div>
                <div class="small col-lg-4 col-md-6 col-sm-6">
                    <h3>Address</h3>
                    <p>4M. El-Nabawi El-Mohandes <br />Nasr City, Cairo.</p>
                </div>
                <div class=" small col-lg-5 col-md-6 col-sm-6">
                    <h3>Contact Us</h3>
                    <contact-us />
                    <div class="socialIcon">
                        <social-icons />
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="end_footer">
      <div class="container">    
                <span>© 2017 Balance . All Rights Reserved</span>
        </div>
</div>
</template>

<script>
import SocialIcons from "@/components/SocialIcons.vue";
import ContactUs from "@/components/ContactUs.vue";

export default {
  name: "AppFooter",
  components: {
    SocialIcons,
    ContactUs
  },
};
</script>

<style lang="scss" scoped>
footer{
    background-color: black;
    color: white;
}
footer .container{
    padding:100px 0;
}
img {
  height: auto;
  max-width: 100%;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 18px;
}
.end_footer{
    background-color: #191919;
    color:#8d8d8c;
    padding: 15px 0;
}
span{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
}
@media (min-width:528px)and(max-width:769px){
    span {
        font-size: 14px;
    }
}
@media (max-width:527px){
    span{
        font-size: 12px;
    }
}
@media (max-width:576px){
    .small{
        margin-left: 20px;
    }
}
@media (max-width:991px){
    .small{
        margin-top:30px;
    }
}
</style>