<template>
  <section>
    <h1>Partners & Clients</h1>
    <div class="container">
      <div class="row">
        <div class="col-3">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src="@/images/partner/oreal.jpg"
                  alt="First slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/moh.jpg" 
                  alt="Second slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/zara.jpg"
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span class="" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span class="" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div class="col-3">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src="@/images/partner/mpw.png"
                  alt="First slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/bankmisr.png"
                  alt="Second slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/marcyrl.png"
                  alt="Third slide"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src="@/images/partner/vox.png"
                  alt="First slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/mahgoub.png"
                  alt="Second slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/hyper.jpg"
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span class="" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span class="" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div class="col-3">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src="@/images/partner/wakipharma.jpg"
                  alt="First slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/downtown.jpg"
                  alt="Second slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="@/images/partner/optaline.png"
                  alt="Third slide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
        <a href="#" class="see-more" @click="$router.push('/Clients')">See More</a>
        </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  margin-bottom: 40px;
  margin-top: 10px;
}
.container {
  padding-top: 25px;
  padding-bottom: 38px;
  background-color: #12baf0;
}
h1 {
  font-weight: bold;
  color: #075daa;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  padding: 30px 0;
  font-size: 36px;
}
.row {
  padding: 5px 0;
}
.see-more {
  font-family: poppins;
  max-width: 212px;
  margin: 0 auto;
  display: block;
  background-color: #12baf0;
  color: white;
  padding: 15px 0px;
  width: 100%;
  font-size: 20px;
  text-align: center;
  border-radius: 32px;
  margin-top: 25px;
  text-decoration: none;
}
.see-more:hover {
  background-color: #075daa;
}
img{
  background-color: white;
}
@media (max-width:322px){
h1{
    font-size:26px;
}
}
</style>