<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                   <img src="@/images/clients/evapharma.jpg" alt="" class="imgSide" @click="$router.push('/EvaPharma')">
                </div>
                <p>EVA Pharma</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/downtown.jpg" alt="" class="imgSide" @click="$router.push('/DownTownMall')">
                </div>
                <p>Down Town Mall</p>
            </div>         
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/amoun.jpg" alt="" class="imgSide" @click="$router.push('/AmounPharmaceutical')">
                </div>
                <p>AMOUN Pharmaceutical</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/reefoasis.jpg" alt="" class="imgSide" @click="$router.push('/ReefOasis')">
                </div>
                <p>Reef Oasis</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/vox.jpg" alt="" class="imgSide" @click="$router.push('/VoxCinema')">
                </div>
                <p>Vox Cinema</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/oreal.jpg" alt="" class="imgSide" @click="$router.push('/OREAL')">
                </div>
                <p>L'OREAL Cosmetics -Egypt</p>
            </div>           
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/wakipharma.jpg" alt="" class="imgSide" @click="$router.push('/WakiPharma')">
                </div>
                <p>WAKI Pharma</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/marcyrl.jpg" alt="" class="imgSide" @click="$router.push('/MarcyrlPharma')">
                </div>
                <p>Marcyrl Pharma</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/nccc.jpg" alt="" class="imgSide" @click="$router.push('/Nccc')">
                </div>
                <p>NCCC (NASR City Cancer center)</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/zagazighospital.jpg" alt="" class="imgSide" @click="$router.push('/ZagazigUniversityHospital')">
                </div>
                <p>Zagazig University Hospital</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/nekhl.jpg" alt="" class="imgSide" @click="$router.push('/NekhlcentralHospital')">
                </div>
                <p>Nekhl central Hospital</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/moeg.jpg" alt="" class="imgSide" @click="$router.push('/MallEgyptShops')">
                </div>
                <p>Mall of Egypt shops</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/mahgoub.jpg" alt="" class="imgSide" @click="$router.push('/MahgoubCeramicGallery')">
                </div>
                <p>Mahgoub ceramic gallery</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/bankmisr.jpg" alt="" class="imgSide" @click="$router.push('/BanqueEgyptClub')">
                </div>
                <p>Banque of Egypt club</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/smc.jpg" alt="" class="imgSide" @click="$router.push('/SpecializedMedicalCouncils')">
                </div>
                <p>Specialized medical councils</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/kattamya.jpg" alt="" class="imgSide" @click="$router.push('/KatameyaClinicHospital')">
                </div>
                <p>Katameya Clinic Hospital</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/alfattah.jpg" alt="" class="imgSide" @click="$router.push('/MasjedAlfattahAlaleemHall')">
                </div>
                <p>Masjed alfattah alaleem Hall (B)</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/horus.jpg" alt="" class="imgSide" @click="$router.push('/EvaNewPharmaFactory')">
                </div>
                <p>Eva new pharma factory of HORUS</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/dokhan.jpg" alt="" class="imgSide" @click="$router.push('/EasternTobaccoCompany')">
                </div>
                <p>Eastern tobacco company</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/portomarina.jpg" alt="" class="imgSide" @click="$router.push('/PortoMarinaAlexandria')">
                </div>
                <p>Porto Marina Alexandria</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/anba.jpg" alt="" class="imgSide" @click="$router.push('/AnbaAntoniuschurch')">
                </div>
                <p>Anba Antonius church</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/deo.jpg" alt="" class="imgSide" @click="$router.push('/DeoCairo')">
                </div>
                <p>DEO Cairo</p>
            </div>      
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/hyper.jpg" alt="" class="imgSide" @click="$router.push('/HyperOne')">
                </div>
                <p>Hyper one</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/post.jpg" alt="" class="imgSide" @click="$router.push('/EgyptianPostOffice')">
                </div>
                <p>Egyptian post office</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/darna.jpg" alt="" class="imgSide" @click="$router.push('/DarnaMall')">
                </div>
                <p>Darna Mall</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/optaline.jpeg" alt="" class="imgSide" @click="$router.push('/OptalineFactory')">
                </div>
                <p>OPTALINE Factory</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/mcdonalds.jpg" alt="" class="imgSide" @click="$router.push('/Mcdonalds')">
                </div>
                <p>McDonalds</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/galalh.jpg" alt="" class="imgSide" @click="$router.push('/AlgalalahResort')">
                </div>
                <p>Al GALALAH Resort</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/mansorahospital.jpg" alt="" class="imgSide" @click="$router.push('/MansouraUniversityHospital')">
                </div>
                <p>Mansoura University Hospital</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/evaadmin.jpg" alt="" class="imgSide" @click="$router.push('/EvaAdminBuilding')">
                </div>
                <p>EVA Admin Building</p>
            </div>   
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/erc.jpg" alt="" class="imgSide" @click="$router.push('/ElectronicsResearchCenter')">
                </div>
                <p>Electronics Research Center</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/almaza.jpg" alt="" class="imgSide" @click="$router.push('/AlmazaCityCenter')">
                </div>
                <p>Almaza city center(shopes)</p>
            </div>      
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/clients/bayadia.png" alt="" class="imgSide" @click="$router.push('/Albayadia')">
                </div>
                <p>ALBAYADIA(ISIS) Hospital</p>
            </div>   
        </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    padding: 40px 0;
}
.img-hover-zoom img{
    width:100%;
}
p{
    text-align: center;
    font-size: 18px;
    color:#000;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
}
.imgSide{
    padding: 0;
    cursor: -webkit-grab; cursor: grab;
}
//* [1] The container */
.img-hover-zoom {
   /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  margin:10px 0;
  border: 2px solid #075daa;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
  width:100%;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

</style>