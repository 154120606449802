<template>
    <div class="container">
        <h1>Capture Hood TESTO 240</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Flow velocity: 0 to 14 m/s /0 to 2750 ft/min <br> Volume flow: 50 to 4000 m³/h /30 to 2350 cfm / 11 to 1100 l/s</span></h4>
                    <h4>Measurement Accuracy: <span>Flow velocity: no accuracy specification since calculated variable <br> Volume flow : ±3% of m.v. +7 cfm at +72 °F, 405 in H₂O (50 to 2,060 cfm)</span></h4>
                    <h4>Units: <span>Flow velocity : m/s / ft/min <br> Volume flow: m³/h / cfm / l/s</span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/clean/c1.jpg" alt="">
                </div>
            </div>
            <h1>Micromanometer PVM620</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Measurement Range: <span>Static / Differential Pressure:-15 to +15 in. H2O (-28.0 to +28.0 mm Hg, -3735 to+3735 Pa) <br> Velocity From a Pitot Tube: 250 to 15500 ft/min (1.27 to 78.7 m/s) </span></h4>
                    <h4>Measurement Accuracy: <span>Static / Differential Pressure: ±1% of reading ±0.005 in. H2O (±0.01 mm Hg, ±1 Pa) <br> Velocity From a Pitot Tube: 250 to 15500 ft/min (1.27 to 78.7 m/s) </span></h4>
                    <h4>Units: <span>Static / Differential Pressure: in. H2O / Pa / mm Hg <br> Velocity From a Pitot Tube: ft/min / m/sec </span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/Instrument/clean/c2.jpg" alt="">
                </div>
            </div>
            
            
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}
.row{
    border-bottom: 2px solid #075daa;
}
.cont2{
    padding-bottom: 10px;
}
@media (max-width: 321px) {
    h1{
        font-size:24px;
    }
    h4{
        font-size: 20px;
    }
    span{
        font-size: 18px;
    }
}
</style>