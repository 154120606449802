<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="img-hover-zoom">
                    <img src="@/images/projects/p1.jpg" alt="" class="imgSide" @click="$router.push('/MPW')">
                </div>
                <p>MPW- New Kuwait Airport II</p>
            </div>
            
        </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    padding: 40px 0;
}
.img-hover-zoom img{
    width:100%;
}
p{
    text-align: center;
    font-size: 18px;
    color:#000;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;;
}
.imgSide{
    padding: 0;
    cursor: -webkit-grab; cursor: grab;
}
//* [1] The container */
.img-hover-zoom {
   /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  margin:10px 0;
  border: 2px solid #075daa;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
  width:100%;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
</style>