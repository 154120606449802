<template>
    <div class="container">
        <h1>MPW- New Kuwait Airport II</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 cont1">
                    <h4>Location: <span>Kuwait country</span></h4>
                    <h4>Scope of work: <span>Commissioning as a third party with cooperation with BARHAN Muhandislic LTD <span class="turki">(Turkish CO)</span></span></h4>
                    <h4>Project size: <span>12000 m2 fire fittings & VRV HVAC system</span></h4>
                    <h4>Consultant: <span>Gulf Consult</span></h4>
                    <h4>Contractor: <span>Limak Insaat <span class="turki">(Turkish CO)</span></span></h4>
                </div>
                <div class="col-lg-5 col-md-5 cont2">
                    <img src="@/images/clients/mpw.jpg" alt="">
                </div>
            </div>
            
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
h1{
    text-transform: uppercase;
    color:#075daa;
    margin-bottom: 15px;
}
h4{
    color: red;
}
span{
    color: black;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
    height: 100%;
}
h1{
    color:#075daa;
}
.turki{
  font-weight: bold;
  text-decoration: underline;
  color:#2581d1;
}
</style>