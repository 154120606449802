<template>
    <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-8 cont1">
                    <h1>Sound Measurement</h1>
                    <p>BALANCE scope of work is including measure and analyses sound measurements for occupied spaces using the most recent sound measurements technics. BALANCE is using the most recent instruments and methodologies as NC and RC methods to record and analyses sound.</p>                 
                </div>
                <div class="col-lg-4 col-md-4 cont2">
                    <img src="@/images/services/sound.jpg" alt="">
                </div>
            </div>
    </div>
</template>
<style lang="scss" scoped>
.container{
    margin:40px 20;
    padding: 30px 15px;
}
@media (max-width: 767px) {
 .cont1:nth-of-type(1) {
    order: 2;
  }
  .cont2:nth-of-type(2) {
    order: 1;
  }
}

.cont2 img {
    width:100%;
}
@media (min-width:765px)and(max-width:768px)
{
    img{
    margin-top:45px;
}
}
@media (max-width: 321px) {
    h1{
        font-size:24px;
    }
    p{
        font-family: 20px;
    }
}
</style>