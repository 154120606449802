<template>
  <div class="about">
    <h1>This is a Services page</h1>
  </div>
</template>

<script>
export default {
  name:"Services",
}
</script>
