<template>
<section>
    <h1>Our Projects</h1>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                  <div class="bd-example">
                      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                          </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="@/images/projects/p1.jpg" class="d-block w-100" alt="..." @click="$router.push('/MPW')">
                                <div class="carousel-caption d-none d-md-block">
                                    <h5>MPW- New Kuwait Airport II</h5>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/images/projects/p2.jpg" class="d-block w-100" alt="..." @click="$router.push('/OREAL')">
                                <div class="carousel-caption d-none d-md-block">
                                    <h5>L'OREAL Cosmetics -Egypt</h5>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/images/projects/p3.jpg" class="d-block w-100" alt="..." @click="$router.push('/VoxCinema')">
                                <div class="carousel-caption d-none d-md-block">
                                    <h5>Vox Cinema</h5>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row col-lg-6">
               <div class=" col-lg-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/projects/p4.jpg" class="col-12 imgSide" @click="$router.push('/EvaPharma')">
                   </div>                 
                    <p>EVA Pharma</p>
                </div>
                <div class=" col-lg-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/projects/p5.jpg" class="col-12 imgSide" @click="$router.push('/DownTownMall')">
                   </div>                 
                    <p>Down Town Mall</p>
                </div>
                <div class=" col-lg-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/projects/p6.jpg" class="col-12 imgSide" @click="$router.push('/AmounPharmaceutical')">
                   </div>                 
                    <p>AMOUN Pharmaceutical</p>
                </div>
                <div class=" col-lg-6">
                   <div class="img-hover-zoom">
                       <img src="@/images/projects/p7.jpg" class="col-12 imgSide" @click="$router.push('/ReefOasis')">
                   </div>                 
                    <p>Reef Oasis</p>
                </div>               
            </div>
        </div>
        <div class="col-md-12">
        <a href="#" class="see-more" @click="$router.push('/EgyProjects')">See More</a>
        </div>
    </div>
    
</section>

</template>

<style lang="scss" scoped>
section{
    margin:20px 0;
    background-color: #e2ebeb;
    box-shadow: 1px 2px #dad8d8;
}
.container{
    padding-top: 25px ;
    padding-bottom: 38px;
}
img{
    width:100%;
    height: 100%;
    
}
h5{
    color: white;
    font-size: 24px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-weight:550;
}
h1{
    font-weight: bold;
    color: #075daa;
    font-family: "Roboto Condensed", sans-serif;
    text-align: center;
    padding: 30px 0;
    font-size: 36px;
}
p{
    text-align: center;
    font-size: 18px;
    color:#000;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
}
.bd-example{
    border: 10px solid #075daa;
    margin-top: 15px;
}
.imgSide{
    padding: 0;
    cursor: -webkit-grab; cursor: grab;
}
//* [1] The container */
.img-hover-zoom {
   /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
//   border: 12px solid red ;
  margin:10px 0;
  
 
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
  width:100%;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
.see-more {
  font-family: poppins;
  max-width: 212px;
  margin: 0 auto;
  display: block;
  background-color:#075daa;
  color: white;
  padding: 15px 0px;
  width: 100%;
  font-size: 20px;
  text-align: center;
  border-radius: 32px;
  margin-top: 25px;
  text-decoration: none;
}
.see-more:hover {
  background-color:#12baf0;
}
@media (max-width:991px){
    .img-hover-zoom {
        margin-left:23px;
    }
    .bd-example{
    margin-top: 0;
    margin-bottom: 5px;
}
}
</style>