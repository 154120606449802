<template>
  <div class="about">
    <h1>This is a Instrumentation page</h1>
  </div>
</template>

<script>
export default {
  name:"Instrumentation",
}
</script>
