<template>
    <nav class="navbar navbar-expand-lg navbar-light ">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="line"></span> 
        <span class="line"></span> 
        <span class="line" style="margin-bottom: 0;"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" @click="$router.push('/')" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span>About</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#"  @click="$router.push('/Background')">Our Background</a>
          <a class="dropdown-item" href="#"  @click="$router.push('/Vision')">Vision</a>
          <a class="dropdown-item" href="#"  @click="$router.push('/Mission')">Mission</a>
          <a class="dropdown-item" href="#"  @click="$router.push('/Clients')">Our Clients</a>
        </div>
      </li>     
      <li class="nav-item active dropdown">
        <a class="nav-link"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span >Services</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#" @click="$router.push('/TestingBalancing')">Testing & Balancing </a>
          <a class="dropdown-item" href="#" @click="$router.push('/CleanRoom')">Clean Room Validation</a>
          <a class="dropdown-item" href="#" @click="$router.push('/SoundMeasure')">Sound Measurement</a> 
        </div>
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span >Projects</span>
        </a>
         <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#" @click="$router.push('/EgyProjects')">Egypt</a>
          <a class="dropdown-item" href="#" @click="$router.push('/NatProjects')">International</a>
        </div>
        <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <div class="dropright">
           <a type="button" class="btn " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             <span class="dropright-item dropdown-toggle" @click="$router.push('/EgyProjects')">Egypt </span>
           </a>
           <div class="dropdown-menu">
                <a class="dropdown-item" href="#">1</a>
                <a class="dropdown-item" href="#">2</a>
              </div>
          </div>
           <div class="dropright">
           <a type="button" class="btn " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             <span class="dropright-item dropdown-toggle" @click="$router.push('/NatProjects')">International </span>
           </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">3</a>
                <a class="dropdown-item" href="#">4</a>
              </div>
         </div>  
        </div> -->
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span >Instrumentation</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#" @click="$router.push('/AirMeasurements')">Air Measurements</a>
          <a class="dropdown-item" href="#" @click="$router.push('/WaterMeasurements')">Water Measurements</a>
          <a class="dropdown-item" href="#" @click="$router.push('/SoundMeasurements')">Sound Measurements</a>
          <a class="dropdown-item" href="#" @click="$router.push('/CleanRoomI')">Clean Room</a>
          <a class="dropdown-item" href="#" @click="$router.push('/ElectricalMeasurements')">Electrical Measurements</a>
        </div>
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span  @click="$router.push('/Community')">Community</span>
        </a>
      </li>
      <li class="nav-item active dropdown">
        <a class="nav-link"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span  @click="$router.push('/Reports')">Reports</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
</template>

<style lang="scss" scoped>
.dropdown:hover>.dropdown-menu {
  display: block;
}
.dropright:hover>.dropdown-menu {
  display: block;
}
.my-dropdown-toggle::after {
    content: none;
}

.navbar-toggler{
    width: 47px;
    height: 34px;
    border:none;
    margin-left: 10px;
}
.navbar-toggler .line{
    width: 100%;
    float: left;
    height: 2px;
    background-color: #fff;
    margin-bottom: 5px;
}
nav,.dropdown-menu{
    background-color: #2581d1;
}
a{
     color: #ffffff
}
.navbar-light .navbar-nav .active>.nav-link{
    color: #ffffff !important;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.04em;
    padding: 12px 25px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}
.dropdown-item:hover{
    color: #12baf0;
}
.dropright-item{
    color: white;
}
@keyframes slideIn {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
nav{
   animation: 1s ease-out 0s 1 slideIn;
}
</style>